import Vue from "vue";
import VueRouter from "vue-router";
import { main, EventBus } from "@/main.js";
//import Home from "../views/Home.vue";

Vue.use(VueRouter);

// API paths for rqeuseting content
// path for requesting page collection schema: /data/api/collections/collection/page
// path for requesting menu collection schema: /data/api/collections/collection/menu
// path for requesting menu collection schema: /data/api/collections/collection/events

// path for geting collection of menus: /api/menu
// path for geting collection of pages: /api/pages
// path for geting a page based on it's url: /api/page/{PAGE URL}
// path for geting collection of churches:
// path for geting collection of events:

const routes = [
  /*{
    path: "/*",
    name: "Home",
    component: Home
  }*/
  /*{
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About.vue")  // webpackChunkName: "about"
    component: () => import("../views/About.vue")
  },*/
  // { path: "/api/*" },
  // { path: "/data/*" },
  // { path: "*", redirect: "/" }
];

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = to => {
  //c onst scrollBehavior = (to, from, savedPosition) => {
  const myMain = main;
  return new Promise(function(resolve) {
    EventBus.$once("loadFinished", function() {
      myMain.$nextTick().then(function() {
        if (to.hash) {
          // scroll to the referenced hash
          resolve({ selector: to.hash });
        } else {
          // scroll to the top of page
          resolve({ x: 0, y: 0 });
        }
      });
    });
  });
};

// creating the router
const router = new VueRouter({
  mode: "history",
  routes: routes,
  scrollBehavior: scrollBehavior
});

export default router;
