<template>
  <div class="menu-language">
    <!--li class="menu-lang-item"-->
    <a
      v-for="(language, index) in visibleLanguages"
      :key="index"
      class="link"
      :href="getHomePathForLanguage(language)"
      :title="getLanguageTitle(language)"
      @click="handleLanguageClick($event, language)"
      >{{ language }}</a
    >
    <!--/li-->
  </div>
</template>

<script>
// import { mapState, mapGetters, mapActions } from "vuex";
import { mapGetters } from "vuex";
//import { EventBus } from "@/main.js";

import LanguageHelper from "@/helpers/LanguageHelper";
import MenuHelper from "@/helpers/MenuHelper";

export default {
  name: "MenuLanguage",

  components: {
    //SubMenu: SubMenu
  },

  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },

  created: function() {
    //const self = this;
  },

  mounted: function() {},

  // component internal reactive data
  data: function() {
    return {};
  },

  // component computed properties
  computed: {
    // importing getters from vuex with mapGetters (made possible by importing mapGetters from vuex)
    // using JS ES6 syntax for merging properties returned by mapGetters into computed properties
    ...mapGetters("language", {
      getLanguage: "getLanguage"
    }),

    ...mapGetters("path", {
      getHomePathForLanguage: "getHomePathForLanguage"
    }),

    //this.$log.debug(this.$route.path);
    language: function() {
      // in case of default hungarian language the stored string is empty
      return this.getLanguage === "" ? "hu" : this.getLanguage;
    },

    visibleLanguages: function() {
      const self = this;
      return LanguageHelper.validLanguages.filter(function(lang) {
        return lang !== self.language;
      });
    }
  },

  // component methods
  methods: {
    requestPageLoad: MenuHelper.requestPageLoad,

    // handles the click event on language items
    // prevents default event propagation
    // requests page load
    handleLanguageClick: function(event, language) {
      event.preventDefault();
      this.requestPageLoad(this.getHomePathForLanguage(language));
      //}
    },

    getLanguageTitle: function(lang) {
      if (lang === "en") return "Switch to English";
      return "Magyar nyelvre váltás";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
#brightbi .header {
  .menu-language {
    position: absolute;
    display: block;
    list-style: none;
    background: none;
    font-size: 1em;
    right: 0.5em;
    top: 0em;
    margin: 0;
    padding: 0;
    text-align: center;

    .link {
      position: relative;
      display: block;
      box-sizing: border-box;
      font-size: 1em;
      width: 2em;
      height: 1.5em;
      margin: 0;
      padding: 0;
      border: none;
      color: $colorWhite; //$colorThemeDarkest;
      //background-color: $colorWhite;
      border: 1px solid $colorWhite;
      border-radius: 0.25em;
      //aspect-ratio: 1/1;
      @include font-site(300, normal);
      text-transform: uppercase;

      line-height: 1.425em;

      text-shadow: none;
      letter-spacing: 0;
      transition-duration: 0.5s;
      cursor: pointer;
      z-index: 1;

      &:hover {
        // background-color: $colorBrown;
        // color: $colorWhite;
        //border: 1px solid $colorWhite;
        color: $colorThemeDarkest;
        background-color: $colorWhite;

        transition-duration: 0.5s;
        cursor: pointer;
      }
    }
  }

  @media only screen and (min-width: 640px) {
  }

  @media only screen and (min-width: 1024px) {
  }
}
</style>
