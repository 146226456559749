<template>
  <h1 v-if="isH1" v-html="textContent" class="heading h1"></h1>
  <h3 v-else-if="isH3" v-html="textContent" class="heading h3"></h3>
  <h4 v-else-if="isH4" v-html="textContent" class="heading h4"></h4>
  <h2 v-else v-html="textContent" class="heading h2"></h2>
</template>

<script>
import handler from "@/plugins/ObjectHandler";

export default {
  name: "ContentTitle",
  /*render: function(createElement) {
    return createElement(this.type, this.textContent);
  },*/
  created: function() {},

  watch: {},
  props: {
    content: {
      //type: Object,
      default: function() {
        return {};
      }
    }
  },
  // component internal data
  data: function() {
    return {};
  },
  computed: {
    isH1: function() {
      return this.content.type === "h1";
    },

    isH3: function() {
      return this.content.type === "h3";
    },

    isH4: function() {
      return this.content.type === "h4";
    },

    textContent: function() {
      return handler.getProperty("", "content", this.content);
    },

    bigtext: function() {
      return handler.getProperty(false, "content", this.content);
    },
    detail: function() {
      return handler.getProperty(false, "detail", this.content);
    },
    url: function() {
      return handler.getProperty("", "url", this.content);
    },
    clickable: function() {
      return this.url !== "";
    },

    //returns linked class name if a valid url is detected
    classlinked: function() {
      return this.clickable ? "linked" : false;
    }
  },
  methods: {
    //using router to navigate to url if the is a url set
    handleClick: function() {
      if (this.classlinked) {
        this.$router.push(this.url);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .heading {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-size: 1em;
    @include font-site(300, normal);
    padding-top: 1em;
    padding-bottom: 1em;
    color: $colorTheme2;
    text-align: center;

    a {
      color: $colorTheme2;
      cursor: pointer;

      &:hover {
        color: $colorTheme3;
        text-decoration: underline;
      }
    }

    &.h1 {
      font-size: 2.5em;
    }

    &.h2 {
      font-size: 2em;
    }

    &.h3 {
      font-size: 1.75em;
    }
  }

  @media only screen and (min-width: $max-content-width) {
    .heading {
      font-size: 2em;

      &.h1 {
        font-size: 3.5em;
      }

      &.h2 {
        font-size: 2.5em;
      }

      &.h3 {
        font-size: 2em;
      }
    }
  }
}

/*
    &.linked:before {
      position: absolute;
      display: block;
      box-sizing: border-box;
      content: "";
      font-size: 1em;
      top: 2.75em;
      right: 0px;
      width: 1.5em;
      height: 1.5em;
      margin: 0px;
      padding: 0px;
      border-top: 1px solid $colorGray;
      border-right: 1px solid $colorGray;
      transform: rotate(45deg);
      transition: right 0.5s;
    }

    &:after {
      position: relative;
      display: block;
      box-sizing: border-box;
      content: "";
      font-size: 1em;
      bottom: 0px;
      width: 100%;
      height: 1.25em;
      margin: 0px;
      padding: 0px;
      //border-bottom: 1px solid $colorGray;
    }

    .big {
      display: block;
      font-size: 2.25em;
      line-height: 120%;
      @include font-site(600, normal);
      color: inherit;
    }

    .detail {
      display: block;
      font-size: 1.5em;
      line-height: 120%;
      @include font-site(300, normal);
      color: inherit;
    }

    &.linked {
      cursor: pointer;
    }

    &.linked:hover {
      color: $colorBrown;

      &.linked:before {
        transition: right 0.5s;
        right: -0.5em;
        border-top: 1px solid $colorBrown;
        border-right: 1px solid $colorBrown;
      }
    }
  }
}
*/
</style>
