// vuex store module
// intended for api functionality, used in conjunction with axios for loading api
//import { forceToString } from "@/plugins/StringUtil";

export default {
  // mark as namespaced
  namespaced: true,

  // storing the loaded api properties of our application
  // the apiMenu is storing response from menu api
  // the apiPage is storing response from page api returning the content of one page
  state: {
    language: ""
  },

  // getter for the state properties are used for accessing them
  getters: {
    /*getMenuModel: function() {
      return {
        _id: 0,
        icon: "",
        menu: "",
        page: {
          _id: 0,
          url: "/"
        },
        submenu: []
      };
    },*/
    // returns the current value of the apiMenu state
    getLanguage: function(state) {
      return state.language;
    }
  },

  // mutating, changing the the state properties
  // !!! IMPORTANT: mutations are and should be always syncronous
  // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
  mutations: {
    // changes the apiMenu state by replacing it with a new array
    setLanguage: function(state, newLanguage) {
      state.language = newLanguage;
    }
  },

  // actions. methods for triggering mutations
  actions: {}
};
