// creating plugin
// returns util functions for colors

// RGBfromHex converts Hex color values to Red-Green-Blue values
// if hex is not a valid hex value #000000 is used
export const RGBfromHex = function(hex) {
  return {
    r: colorFromHex("r", filterHex(hex)),
    g: colorFromHex("g", filterHex(hex)),
    b: colorFromHex("b", filterHex(hex))
  };
};

// returns a requested color component value from Hex color converted to integer
const colorFromHex = function(color, hex) {
  const chars = hex.length === 7 ? 2 : 1;
  const index = color === "r" ? 1 : color === "g" ? 1 + chars : 3 + chars * chars - chars;
  const val = "0x" + hex.substring(index, index + chars);
  return parseInt(val, 16);
};

// checks if a hex color value is valid
const filterHex = function(hex) {
  const regexpRule = /^#([0-9abcdefABCDEF]{1,2}){3}$/;
  return regexpRule.test(hex) ? hex : "#000000";
};

// HSLfromRGB converts Red-Green-Blue color values to Hue-Saturation-Lightness values
export const HSLfromRGB = function(R, G, B) {
  const rgb = makeObjectRGB(R, G, B);
  const chanel = chanelInfoFromRGB(rgb);
  const lightness = calculateLightness(chanel);
  const hue = hueFromRGB(rgb, chanel);
  const saturation = calculateSaturation(chanel, lightness);
  return {
    h: hue,
    s: saturation,
    l: lightness
  };
};

// checks if an RGB color value is valid. If is not valid returns 0
const filterColorRGB = function(color) {
  return color + 0 === color && color >= 0 && color <= 255 ? color : 0;
};

// makes an RGB object from R G B values, where r, g, and b will be fractions of 1
const makeObjectRGB = function(R, G, B) {
  return {
    r: filterColorRGB(R) / 255,
    g: filterColorRGB(G) / 255,
    b: filterColorRGB(B) / 255
  };
};

// calculate the max chanel value from RGB
const chanelMaxFromRGB = function({ r, g, b }) {
  return Math.max(r, g, b);
};

// calculate the min chanel value  from RGB
const chanelMinFromRGB = function({ r, g, b }) {
  return Math.min(r, g, b);
};

// calculate delta
const deltaFromRGB = function(rgb) {
  return chanelMaxFromRGB(rgb) - chanelMinFromRGB(rgb);
};

// return chanel info object from rgb
const chanelInfoFromRGB = function(rgb) {
  return {
    cmin: chanelMinFromRGB(rgb),
    cmax: chanelMaxFromRGB(rgb),
    delta: deltaFromRGB(rgb)
  };
};

// calculates lightness value from chanel info object using cmin and cmax values
const calculateLightness = function(chanel) {
  const lightness = (chanel.cmin + chanel.cmax) / 2;
  return Math.round(lightness * 100);
};

// calculates saturation value from chanel info object using cmin and cmax and delta values and lightness
const calculateSaturation = function(chanel, lightness) {
  var saturation = chanel.delta === 0 ? 0 : chanel.delta / (1 - Math.abs((2 * lightness) / 100 - 1));
  return Math.round(saturation * 100);
};

//return hue
const hueFromRGB = function(rgb, chanel) {
  var h = 0;
  if (chanel.delta > 0) {
    h = hueForMaxR(h, rgb, chanel);
    h = hueForMaxG(h, rgb, chanel);
    h = hueForMaxB(h, rgb, chanel);
    h = Math.round(h * 60);
    h = h < 0 ? h + 360 : h;
  }
  return h;
};

// returns hue value if R is the max from RGB
const hueForMaxR = function(hue, { r, g, b }, chanel) {
  return chanel.cmax === r ? ((g - b) / chanel.delta) % 6 : hue;
};

// returns hue value if G is the max from RGB
const hueForMaxG = function(hue, { r, g, b }, chanel) {
  return chanel.cmax > r && chanel.cmax === g ? (b - r) / chanel.delta + 2 : hue;
};

// returns hue value if B is the max from RGB
const hueForMaxB = function(hue, { r, g, b }, chanel) {
  return chanel.cmax > r && chanel.cmax > g && chanel.cmax === b ? (r - g) / chanel.delta + 4 : hue;
};
