<template>
  <div id="brightbi">
    <Header></Header>
    <Loader></Loader>
    <Content></Content>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
//import store from "@/store/store";
//import router from "@/router/routes";
import resize from "@/plugins/ResizeHandler";
import { main, EventBus } from "@/main";
import { getAbsoluteOffsetFromDocument } from "@/plugins/DomUtil";
import LanguageHelper from "@/helpers/LanguageHelper";

export default {
  name: "App",

  // component properties defined through DOM element
  props: {},

  // component internal data
  data: function() {
    return {};
  },

  // component created lifecycle, called when instance is created
  created: function() {},

  // component mounted lifecycle, called when the DOM element is mounted
  mounted: function() {
    // detecting window resize event
    resize.addResizeListener(this.notifyResize);

    // detect language
    this.initForLanguage();

    // scroll on first load if needed
    if (this.shouldScrollOnFirstLoad()) {
      this.scrollOnFirstLoad();
    }
  },

  // destroying the winow resize event listener
  beforeDestory: function() {
    resize.removeResizeListener(this.notifyResize);
  },

  // component computed properties
  computed: {
    ...mapGetters("path", {
      getHomePath: "getHomePath"
    }),

    language: function() {
      return LanguageHelper.getLanguageFromPath(this.$route.path);
    }
  },

  // compnent filter methods
  filters: {},

  // component methods
  methods: {
    // importing actions from vuex with mapActions (made possible by importing mapActons from vuex)
    // using JS ES6 syntax for merging properties returned by mapActons into methods
    // the first parameter is the namespace
    ...mapMutations("language", {
      setLanguage: "setLanguage"
    }),

    ...mapActions("api", {
      loadApiMenu: "loadApiMenu",
      loadApiFooter: "loadApiFooter",
      loadApiPage: "loadApiPage",
      loadApiPages: "loadApiPages"
    }),

    // emits global resize event on EventBus
    notifyResize: function() {
      EventBus.$emit("resize");
    },

    // returns true or false depending if hash is worth handling for scroll or not
    shouldScrollOnFirstLoad: function() {
      return this.$route.hash === window.location.hash && this.$route.hash.length > 1;
    },

    // scrolls to element referenced in hash on first load
    // previous check is needed before calling making sure have a valid hash worth handling
    scrollOnFirstLoad() {
      const self = this;
      EventBus.$once("loadFinished", function() {
        // on nextTick findt the element with hash id, and
        main.$nextTick().then(function() {
          const elem = window.document.getElementById(self.$route.hash.substring(1));
          if (elem !== null) {
            const pos = getAbsoluteOffsetFromDocument(elem);
            window.scrollTo(0, pos.y);
          }
        });
      });
    },

    loadApis() {
      //this.$log.debug("loading APIs");
      this.loadApiMenu();
      this.loadApiPage();
      this.loadApiPages();
      this.loadApiFooter();
    },

    initForLanguage() {
      // detect language
      this.setLanguage(this.language);
      this.loadApis();
    }
  },

  //component property or data watching
  watch: {
    // detect language on URL path change
    $route: function() {
      //this.$log.debug("route change detected");
      this.setLanguage(this.language);
    },

    getHomePath: function() {
      //this.$log.debug("homepath change detected", this.getHomePath);
      this.loadApis();
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_fonts.scss";
@import "@/scss/_resets.scss";

#brightbi {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  max-width: $max-width;
  margin: 0 auto 0 auto;
  padding: 0;
  border: none;
  color: $colorGray;
  background-color: $colorBlack;

  //background-image: -webkit-gradient(ellipse at left top, left bottom, from(#024043), to(#00acb1));

  a:link {
    color: $colorGray;
  }
  a:active {
    color: $colorGray;
  }
  a:hover {
    color: $colorBrown;
  }
  a:visited {
    color: $colorGray;
  }
}
</style>
