// vuex store module
// intended for api functionality, used in conjunction with axios for loading api
import LoadService from "@/services/LoadService";
import ObjectHandler from "@/plugins/ObjectHandler";
import router from "@/router/routes";

export default {
  // mark as namespaced
  namespaced: true,

  // storing the loaded api properties of our application
  // the apiMenu is storing response from menu api
  // the apiPage is storing response from page api returning the content of one page
  state: {
    apiMenu: [],
    apiFooter: [],
    apiPage: [],
    apiPages: []
  },

  // getter for the state properties are used for accessing them
  getters: {
    /*getMenuModel: function() {
      return {
        _id: 0,
        icon: "",
        menu: "",
        page: {
          _id: 0,
          url: "/"
        },
        submenu: []
      };
    },*/
    // returns the current value of the apiMenu state
    getApiMenu: function(state) {
      return state.apiMenu;
    },

    getApiFooter: function(state) {
      return state.apiFooter;
    },

    getApiPage: function(state) {
      return state.apiPage;
    },

    getApiPages: function(state) {
      return state.apiPages;
    },

    // returns a menu for a given menuid string if found otherwise returns empty object
    getMenuWithId: state => id => {
      return state.apiMenu.find(menu => menu._id === id) || {};
    },

    // returns a menu for a given url string if found otherwise returns empty object
    getMenuWithUrl: state => url => {
      return state.apiMenu.find(menu => menu.page.url === url) || {};
    },

    // returns the home menu if found otherwise returns empty object
    getHomeMenu: (state, getters, rootState, rootGetters) => {
      const url = rootGetters["path/getHomePath"];
      //console.log("getHomeMenu", url);
      return getters.getMenuWithUrl(url);
    },

    // returns submenus for menu with url if found otherwise returns empty array
    getSubMenuForUrl: (state, getters) => url => {
      var mymenu = getters.getMenuWithUrl(url);
      return Array.isArray(mymenu.submenu) ? mymenu.submenu : [];
    },

    // returns submenus id for menu with url if found otherwise returns empty array
    getSubMenuIdsForUrl: (state, getters) => url => {
      var submenus = getters.getSubMenuForUrl(url);
      return ObjectHandler.getEachPropVal("_id", submenus);
    },

    // returns submenus for menu with url if found otherwise returns empty array
    getSubPagesForUrl: (state, getters) => url => {
      var submenuIds = getters.getSubMenuIdsForUrl(url);
      return ObjectHandler.getEachWithPropVal("_id", submenuIds, state.apiMenu);
    },

    // returns the main menu for the apropriate language
    getMainMenu: (state, getters, rootState, rootGetters) => {
      const url = rootGetters["path/getHomePath"];
      return getters.getSubPagesForUrl(url);
    },

    //
    getMainFooter: (state, getters, rootState, rootGetters) => {
      const url = rootGetters["path/getHomePath"];
      return getters.getFooterSubPagesForUrl(url);
    },

    // returns submenus id for menu with url if found otherwise returns empty array
    getFooterSubPagesForUrl: (state, getters) => url => {
      var submenuIds = getters.getFooterSubMenuIdsForUrl(url);
      return ObjectHandler.getEachWithPropVal("_id", submenuIds, state.apiFooter);
    },

    // returns submenus id for menu with url if found otherwise returns empty array
    getFooterSubMenuIdsForUrl: (state, getters) => url => {
      var submenus = getters.getFooterSubMenuForUrl(url);
      return ObjectHandler.getEachPropVal("_id", submenus);
    },

    // returns submenus for menu with url if found otherwise returns empty array
    getFooterSubMenuForUrl: (state, getters) => url => {
      var mymenu = getters.getFooterMenuWithUrl(url);
      return Array.isArray(mymenu.submenu) ? mymenu.submenu : [];
    },

    getFooterMenuWithUrl: state => url => {
      return state.apiFooter.find(menu => menu.page.url === url) || {};
    }
  },

  // mutating, changing the the state properties
  // !!! IMPORTANT: mutations are and should be always syncronous
  // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
  mutations: {
    // changes the apiMenu state by replacing it with a new array
    setApiMenu: function(state, newArray) {
      state.apiMenu = ObjectHandler.returnArray(newArray);
    },

    setApiFooter: function(state, newArray) {
      state.apiFooter = ObjectHandler.returnArray(newArray);
    },

    setApiPage: function(state, newArray) {
      state.apiPage = ObjectHandler.returnArray(newArray);
    },

    setApiPages: function(state, newArray) {
      state.apiPages = ObjectHandler.returnArray(newArray);
    }
  },

  // actions. methods for triggering mutations
  actions: {
    // loads asynchronously the apiMenu
    async loadApiMenu(context) {
      // gets acynchronously the menu JSON
      // saves the recieved value to state by commiting mutation
      const url = context.rootGetters["path/getHomePath"];
      const response = await LoadService.getMenu(url);
      context.commit("setApiMenu", response.data);
      // dispatching action is used for calling an action
      // context.dispatch("actionName");
    },

    // loads asynchronously the apiMenu
    async loadApiFooter(context) {
      // gets acynchronously the menu JSON
      // saves the recieved value to state by commiting mutation
      const url = context.rootGetters["path/getHomePath"];
      const response = await LoadService.getFooter(url);
      context.commit("setApiFooter", response.data);
    },

    // loads asynchronously the apiPage
    async loadApiPage(context) {
      // gets acynchronously the page content JSON
      // saves the recieved value to state by commiting mutation
      const response = await LoadService.getPage(router.currentRoute.path);
      context.commit("setApiPage", response.data);
    },

    // loads asynchronously the apiPage
    async loadApiPages(context) {
      // gets acynchronously the valid pages list
      // saves the recieved value to state by commiting mutation
      const url = context.rootGetters["path/getHomePath"];
      const response = await LoadService.getPages(url);
      context.commit("setApiPages", response.data);
    }
  }
};
