<template>
  <router-link class="page-link" :to="linkedPageUrl" :class="{ simple: hasNoDescription }">
    <!--a class="page-link" @click.prevent.stop="handleClick" :href="linkedPageUrl" :class="{ simple: hasNoDescription }"-->
    <span class="cell">
      <span class="title">{{ title }}</span>
      <span class="content" v-if="!hasNoDescription">{{ description }}</span>
      <ContentLinkArrow></ContentLinkArrow>
    </span>
    <!--/a-->
  </router-link>
</template>

<script>
import { mapGetters } from "vuex";
import handler from "@/plugins/ObjectHandler";
import ContentLinkArrow from "@/components/ContentLinkArrow";

export default {
  name: "ContentLink",
  components: {
    ContentLinkArrow: ContentLinkArrow
  },
  created: function() {},
  mounted: function() {},
  watch: {},
  props: {
    content: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters("api", {
      getApiPages: "getApiPages"
    }),

    title: function() {
      return handler.getProperty("", ["value", "title"], this.content);
    },

    description: function() {
      return handler.getProperty("", ["value", "content"], this.content);
    },

    linkedPageId: function() {
      return handler.getProperty("", ["value", "page", "_id"], this.content);
    },

    linkedPageObj: function() {
      return handler.getFirstWithPropVal(["_id"], this.linkedPageId, this.getApiPages);
    },

    linkedPageUrl: function() {
      return handler.getProperty("/", ["url"], this.linkedPageObj);
    },

    hasNoDescription: function() {
      return this.description === "";
    }
  },

  methods: {
    handleClick() {
      this.$log.debug("ContentLink click");
      this.$router.push(this.linkedPageUrl);
    }
  }
};
</script>

<style lang="scss">
/* base font size is 16px */
#brightbi .content .links {
  .page-link {
    position: relative;
    box-sizing: border-box;
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    width: 100%;
    min-width: 100%;
    min-height: 5.75em;
    padding: 0;
    border: none;

    cursor: pointer;

    &:nth-child(4n + 1) {
      background-color: $colorThemeDarkest;
    }

    &:nth-child(4n + 2) {
      background-color: $colorTheme3;
    }

    &:nth-child(4n + 3) {
      background-color: $colorTheme;
    }

    &:nth-child(4n + 0) {
      background-color: $colorThemeDark;
    }

    // balck text on lighter backgrounds
    &:nth-child(4n + 2),
    &:nth-child(4n + 3) {
      .title,
      .content {
        color: $colorBlack;
      }
      .icon .shape {
        fill: $colorBlack;
        //filter: drop-shadow(0 0.25em 0.25em $colorBlack);
      }
    }

    .cell {
      display: block;
      position: relative;
      box-sizing: border-box;
      display: block;
      max-width: 40ch;
      height: 100%;
      margin: 0 auto 0 auto;
      padding: 2em 2em 8em 2em;
    }

    .title {
      position: relative;
      box-sizing: border-box;
      display: block;
      font-size: 1.5em;
      line-height: 115%;
      margin: 0;
      padding: 0;
      @include font-site(normal, normal);
      color: $colorWhite;
      transition-property: text-shadow;
      transition-duration: 0.25s;
    }

    .content {
      position: relative;
      box-sizing: border-box;
      display: block;
      font-size: 1em;
      margin: 0;
      padding: 0;
      @include font-site(300, normal);
      line-height: 150%;
      color: $colorWhite;
      transition-property: text-shadow;
      transition-duration: 0.25s;
    }

    .icon {
      left: 2em;
      bottom: 2em;

      .shape {
        fill: $colorWhite;
      }
    }

    &.simple {
      .cell {
        height: auto;
        //min-height: 0.5em;
        max-width: 60ch;
        padding: 2em 8em 2em 2em;
      }

      //.title {
      //display: inline;
      //}

      .icon {
        //display: none;
        left: auto;
        right: 2em;
        bottom: auto;
        top: 1em;
      }
    }

    &:hover {
      background-image: radial-gradient(120% 90% at center top, $colorThemeDark 5%, $colorBlack 150%);

      .title,
      .content {
        color: $colorWhite;
        //text-shadow: 0 0.25em 0.25em $colorBlack;
        text-shadow: 0 -1px 3px $colorBlack, 0 0 0.125em $colorWhite;
        transition-property: text-shadow, background-image;
        transition-duration: 0.25s;
      }
      .icon {
        .shape {
          fill: $colorWhite;
          //filter: drop-shadow(0 0.25em 0.25em $colorBlack);
        }
      }
    }
  }

  @media only screen and (min-width: 640px) {
    .page-link {
      width: 50%;
      min-width: 50%;
    }
  }

  @media only screen and (min-width: $max-content-width) {
    .page-link {
      width: 25%;
      min-width: 25%;
    }
  }
}
</style>
