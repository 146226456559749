<template>
  <section class="hero" @click="handleClick" :class="classForBackground">
    <div class="module">
      <ContentPicture :content="picture" class="window"></ContentPicture>
      <div class="half">
        <div class="cell">
          <h2 class="message">{{ message }}</h2>
          <div class="detail" v-if="hasDetail" v-html="detail"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import { mapGetters } from "vuex";
import handler from "@/plugins/ObjectHandler";
//import { transmuteToAscii } from "@/plugins/StringUtil";
import ContentPicture from "@/components/ContentPicture";

export default {
  name: "ContentHero",
  components: {
    ContentPicture
  },
  created: function() {},
  mounted: function() {},
  watch: {},
  props: {
    content: {
      //type: Object,
      default: function() {
        return {};
      }
    }
  },
  data: function() {
    return {};
  },
  computed: {
    classForBackground: function() {
      return handler.getProperty("", ["background"], this.content);
    },
    message: function() {
      return handler.getProperty("", ["content"], this.content);
    },
    detail: function() {
      return handler.getProperty("", ["detail"], this.content);
    },
    hasDetail: function() {
      return this.detail !== undefined;
    },
    picture: function() {
      return this.content.picture;
    },
    pictureId: function() {
      return handler.getProperty(false, ["picture", "_id"], this.content);
    },
    picturePath: function() {
      return this.pictureId !== false ? "/api/picture/" + this.pictureId : false;
    }
  },

  methods: {
    //returns a date from string
    handleClick() {
      if (this.clickable) {
        this.$router.push(this.pageurlPlusHash);
      }
    }
  }
};
</script>

<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .hero {
    position: relative;
    box-sizing: border-box;
    display: block;
    //flex-direction: column-reverse;
    font-size: 1em;
    width: 100%;
    max-width: $max-width; //1280px;
    margin: 0 auto 0 auto;
    padding: 0;
    border: none;
    color: $colorWhite;

    .module {
      padding: 0;
    }

    .picture {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
      border: none;
      overflow: hidden;

      .image {
        width: 200%;
        margin-left: -100%;
      }
    }

    .message {
      position: relative;
      display: block;
      box-sizing: border-box;
      font-size: 2em;
      margin: 0;
      padding: 0;
      @include font-site(300, normal);
      color: inherit;
    }

    .detail {
      position: relative;
      display: block;
      box-sizing: border-box;
      font-size: 1em;
      margin: 1em 0 0 0;
      padding: 0;
      @include font-site(300, normal);
      line-height: 150%;
      color: inherit;

      a {
        text-decoration: underline;
        color: inherit;
        cursor: pointer;

        &:hover {
          color: inherit;
          text-decoration: underline dotted;
        }
      }

      ul,
      ol {
        display: block;

        li {
          display: list-item;
          list-style-position: inside;
        }
      }
    }

    .half {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 100%;
      max-width: 40ch;
      margin: 0 auto 0 auto;
      padding: 2em;
      border: none;

      .cell {
        position: relative;
        display: block;
        box-sizing: border-box;
      }
    }
  }

  @media only screen and (min-width: 640px) {
    .hero {
      display: block;
      flex-direction: row;

      // ghost element for clearing the floats
      // floated elements are used for putting pic in the background, and sizing the hero vertically to the pic height
      &:after {
        position: relative;
        display: block;
        box-sizing: border-box;
        content: "";
        clear: both;
      }

      // picture is used as a ghost element to define the minimum height of the hero
      .picture {
        position: relative;
        display: block;
        box-sizing: border-box;
        width: 100%;
        max-width: 100%;
        border: none;
        float: left;
        margin-right: -100%;

        // reseting the image to normal size
        // in mobile view the image width is doubled and the image was moved to left, showing only the right side of the image
        .image {
          width: 100%;
          margin-left: 0;
        }
      }

      .message {
        font-size: 2em;
        //font-size: 3.5vw;
      }

      .detail {
        font-size: 1em;
        //font-size: 1.75vw;
      }

      // content aligned vertically to middle
      // inline-block vertical-align trick is used with children and gost pseudo element
      .half {
        position: relative;
        display: block;
        box-sizing: border-box;
        width: 50%;
        max-width: 50%;
        min-height: 100%;
        margin: 0;
        border: none;

        &:before {
          position: relative;
          display: inline-block;
          box-sizing: border-box;
          content: "";
          width: 0;
          padding-top: 100%;
          vertical-align: middle;
        }

        .cell {
          position: relative;
          display: inline-block;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          border: none;
          vertical-align: middle;
        }
      }
    }
  }

  @media only screen and (min-width: $max-content-width) {
    .hero {
      .message {
        font-size: 3em;
      }
      .detail {
        font-size: 1.5em;
      }
    }
  }

  /*
  @media only screen and (min-width: 1180px) {
    .hero {
      font-size: 1.5em;
    }
  }

  @media only screen and (min-width: 1024px) {
    .hero {
      font-size: 1.25em;
    }
  }

  @media only screen and (min-width: 800px) {
    .hero {
      font-size: 1.125em;
    }
  }
  */
}
</style>
