// how to update dependecies
// this is used to update the minor releases
// npm update
// this is used for updating major releases (doing this can break functionality)
// npm install -g npm-check-updates
// ncu -u
// npm install
// npm update

// import external vendor dependencies
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router/routes";
import store from "@/store/store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLogger from "vuejs-logger";
import SlideUpDown from "vue-slide-up-down";

// import global component files from current project, and register them
import Loader from "@/components/Loader.vue";
import Header from "@/components/Header.vue";
import Content from "@/components/Content.vue";
import Footer from "@/components/Footer.vue";
Vue.component("Loader", Loader);
Vue.component("Header", Header);
Vue.component("Content", Content);
Vue.component("Footer", Footer);

// register vendor components
Vue.component("slide-up-down", SlideUpDown);

//import plugins
//import KrevoApiGetter from "@/plugins/KrevoApiGetter";
//Vue.use(KrevoApiGetter);

//create settings for vendor components
const isProduction = process.env.NODE_ENV === "production";
const vueLoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};
// register vendor components globally, this way no import is needed befor using in Vue
Vue.use(VueLogger, vueLoggerOptions);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

// creating the event bus instance
export const EventBus = new Vue();

// creating the vue instance
export const main = new Vue({
  router: router,
  store: store,
  render: h => h(App)
}).$mount("#brightbi");

export default main;
