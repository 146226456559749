<template>
  <picture class="picture" :class="classes">
    <source v-for="(src, index) in imageSources" :media="src.media" :srcset="src.srcset" :key="'src' + index" />
    <img class="image module" :src="getSrc(1024)" :alt="info" @load="isLoaded" />
  </picture>
  <!--h2 class="title">
    <span class="big" v-if="bigtext !== false">{{ bigtext }}</span>
    <span class="detail" v-if="detail !== false">{{ detail }}</span>
  </h2-->
</template>

<script>
import handler from "@/plugins/ObjectHandler";

export default {
  name: "ContentPicture",
  created: function() {},
  mounted: function() {
    //this.$log.debug(this.$el.getElementsByClassName("image"));
  },
  watch: {},
  props: {
    content: {
      //type: Object,
      default: function() {
        return {};
      }
    },
    loaded: {
      type: Function,
      default: function() {
        return function() {};
      }
    }
  },
  // component internal data
  data: function() {
    return {
      //sizes: [320, 640, 800, 960, 1024, 1280, 1400, 1600, 1920, 2048]
      sizes: [2048, 1920, 1600, 1400, 1280, 1024, 960, 800, 640, 320]
    };
  },
  computed: {
    imageSources: function() {
      const path = this.picturePath;
      if (path === false) return [];
      return this.sizes.reduce(function(result, size, index, sizesArray) {
        const minWidth = index + 1 < sizesArray.length ? sizesArray[index + 1] : 10;
        const source = {
          srcset: path + "/" + size,
          media: "(min-width: " + minWidth + "px)"
        };
        result.push(source);
        return result;
      }, []);
    },
    pictureId: function() {
      return handler.getProperty(false, "_id", this.content);
    },
    picturePath: function() {
      return this.pictureId === false ? false : "/api/picture/" + this.pictureId;
    },
    info: function() {
      return handler.getProperty("", "info", this.content);
    },

    // returns a class name that changes the display width of the image
    // values returned: window, content, narrow
    classWidth: function() {
      return handler.getProperty("", "width", this.content);
    },
    classForBackground: function() {
      return handler.getProperty("", ["background"], this.content);
    },
    classes: function() {
      return this.classWidth + " " + this.classForBackground;
    }
  },
  methods: {
    getMediaForSizeIndex: function(index) {
      //const minWidth = index > 0 && index < this.sizes.length ? this.sizes[index - 1] : 10;
      //const minWidth =  index === 0 ? this.sizes[index - 1] : 10;
      return "(min-width: " + this.sizes[index] + "px)";
    },
    getSrc: function(size) {
      return this.picturePath === false ? false : this.picturePath + "/" + size;
    },

    isLoaded: function() {
      this.loaded();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .picture {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    //margin: 0 auto 0 auto;
    overflow: hidden;
    border: none;

    /*
    &.window {
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    &.narrow {
      width: 60%;
    }
    */

    .image {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      margin: 0 auto 0 auto;
      padding: 0px;
      overflow: hidden;
      border: none;
    }

    &.window {
      .image {
        width: 100%;
        max-width: none;
      }
    }

    &.narrow {
      .image {
        width: 60%;
      }
    }
  }

  .text + .picture {
    margin-top: 2em;
  }

  /*@media only screen and (max-width: $breakLimit) {
    .picture {
      padding: 0px;
    }
  }*/
}
</style>
