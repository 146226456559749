// creating plugin
// returning string utility functions

/*export default {
  // ***************************************************************************
  // transforms accented hungarian characters to ascii characters
  transmuteToAscii: function(str) {
    var result = [];
    for (var i = 0; i < str.length; i++) {
      var replace = this.accents[str[i]];
      result.push(replace === undefined ? str[i] : replace);
    }
    return result.join("").replace(this.nonAlphaNum, "");
  },

  // regexp for non alpahnumeric characters
  nonAlphaNum: /[^a-z0-9-]/gi,

  // object with accented hungarian characters
  accents: {
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ö: "O",
    Ő: "O",
    Ú: "U",
    Ü: "U",
    Ű: "U",
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ö: "o",
    ő: "o",
    ú: "u",
    ü: "u",
    ű: "u"
  },

  extendString: function() {
    var self = this;
    String.prototype.kRevo = {
      transmuteToAscii: function() {
        return self.transmuteToAscii(this);
      }
    };
  }
};*/

export const isString = function(str) {
  return str + "" === str;
};

export const forceToString = function(str, defaultStr = "") {
  return isString(str) ? str : defaultStr;
};

// transforms accented hungarian characters to ascii characters
export const transmuteToAscii = function(str) {
  var result = [];
  for (var i = 0; i < str.length; i++) {
    var replace = accents[str[i]];
    result.push(replace === undefined ? str[i] : replace);
  }
  return result.join("").replace(nonAlphaNum, "");
};

// regexp for non alpahnumeric characters
const nonAlphaNum = /[^a-z0-9-]/gi;

// object with accented hungarian characters
const accents = {
  Á: "A",
  É: "E",
  Í: "I",
  Ó: "O",
  Ö: "O",
  Ő: "O",
  Ú: "U",
  Ü: "U",
  Ű: "U",
  á: "a",
  é: "e",
  í: "i",
  ó: "o",
  ö: "o",
  ő: "o",
  ú: "u",
  ü: "u",
  ű: "u"
};

// left pads the provided string to the requried length with the given character
// s is the string
// l is the length
// c is the caharacter
export const padStringLeft = function(s, l, c) {
  var res = s + "" === s || s + 0 === s ? s + "" : "";
  l = l + 0 === l ? l : 1;
  c = c + "" === c || c + 0 === c ? c : " ";
  while (res.length < l) {
    res = c + res;
  }
  return res;
};
