import ApiService from "@/services/ApiService";
import { forceToString } from "@/plugins/StringUtil";

export default {
  getMenu(params) {
    const apiUrl = "/menu" + forceToString(params, "");
    return ApiService().get(apiUrl);
  },

  getFooter(params) {
    const apiUrl = "/footer" + forceToString(params, "");
    return ApiService().get(apiUrl);
  },

  getPage(params) {
    const apiUrl = "/page" + forceToString(params, "");
    return ApiService().get(apiUrl);
  },

  getGallery(params) {
    const apiUrl = "/gallery" + forceToString(params, "");
    return ApiService().get(apiUrl);
  },

  getPages(params) {
    const apiUrl = "/pages" + forceToString(params, "");
    return ApiService().get(apiUrl);
  }
};
