// vuex store module
// intended for api functionality, used in conjunction with axios for loading api
//import { forceToString } from "@/plugins/StringUtil";
//import LanguageHelper from "@/helpers/LanguageHelper";

export default {
  // mark as namespaced
  namespaced: true,

  // storing the loaded api properties of our application
  // the apiPath is storing the homepath value based on the current language. This path is used for accessing the API-s
  state: {
    pathSeparator: "/",
    homePath: {
      hu: "/",
      en: "/en/"
    }
  },

  // getter for the state properties are used for accessing them
  getters: {
    /*getMenuModel: function() {
      return {
        _id: 0,
        icon: "",
        menu: "",
        page: {
          _id: 0,
          url: "/"
        },
        submenu: []
      };
    },*/
    /*getLanguageStringForPath: function(state, getters, rootState, rootGetters) {
      const language = rootGetters["language/getLanguage"];
      return language !== LanguageHelper.validLanguages[0] ? language : "";
    },

    getPathStart: function(state) {
      return state.pathSeparator;
    },

    getPathClose: function(state, getters) {
      return getters.getLanguageStringForPath !== "" ? state.pathSeparator : "";
    },
    // returns the current value of the apiMenu state
    getPathForLanguage: function(state, getters) {
      return getters.getPathStart + getters.getLanguageStringForPath + getters.getPathClose;
    },*/

    // this function returns the home path string for each language
    // needs to be updated for each enabled language
    getHomePath: function(state, getters, rootState, rootGetters) {
      const language = rootGetters["language/getLanguage"];
      return getters.getHomePathForLanguage(language);
    },

    getHomePathForLanguage: state => language => {
      if (language === "en") return state.homePath.en;
      return state.homePath.hu;
    }
  },

  // mutating, changing the the state properties
  // !!! IMPORTANT: mutations are and should be always syncronous
  // !!! NEVER USE asynchronous timers or loaders in mutations - actions should be used for asynchronous tasks
  mutations: {
    // changes the apiMenu state by replacing it with a new array
  },

  // actions. methods for triggering mutations
  actions: {}
};
