<template>
  <section class="links" :class="classForBackground">
    <div class="module">
      <ContentLink v-for="(linkItem, index) in content" :key="'link' + index" :content="linkItem"></ContentLink>
    </div>
  </section>
</template>

<script>
import ContentLink from "@/components/ContentLink";
//import { mapGetters } from "vuex";
//import handler from "@/plugins/ObjectHandler";
//import { transmuteToAscii } from "@/plugins/StringUtil";

export default {
  name: "ContentLinks",
  components: {
    ContentLink: ContentLink
  },
  created: function() {},
  mounted: function() {},
  watch: {},
  props: {
    content: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: function() {
    return {};
  },
  computed: {
    classForBackground: function() {
      return this.content.background;
    }
  },

  methods: {
    /* handleClick() {
      if (this.clickable) {
        this.$router.push(this.pageurlPlusHash);
      }
    }*/
  }
};
</script>

<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .links {
    position: relative;
    box-sizing: border-box;
    display: block;
    font-size: 1em;
    width: 100%;
    margin: 0 auto 0 auto;
    padding: 0;
    border: none;
    color: $colorWhite;
    /*
    border-top: 1px solid rgb(255, 255, 255, 0.25);
    border-bottom: 1px solid rgb(255, 255, 255, 0.25);
    border-left: none;
    border-right: none;
    */

    .module {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      gap: 0;
      max-width: 100%;
      padding: 0;
    }
  }
}
</style>
