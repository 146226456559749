<template>
  <section class="part" :class="classForBackground">
    <div class="text module" v-html="htmlContent"></div>
  </section>
</template>

<script>
//import { mapGetters, mapActions } from "vuex";
import handler from "@/plugins/ObjectHandler";

export default {
  name: "ContentText",

  created: function() {},
  mounted: function() {},
  watch: {},
  props: {
    content: {
      //type: Object,
      default: function() {
        return {};
      }
    } /*,
      limit: {
        type: Number,
        default: 4
      }*/
  },
  data: function() {
    return {
      dates: {}
    };
  },
  computed: {
    htmlContent: function() {
      //if content is provided as string
      if (typeof this.content === "string") return this.content;
      // if content is provided as object
      return handler.getProperty("", ["text"], this.content);
    },
    classForBackground: function() {
      return handler.getProperty("", ["background"], this.content);
    }
  },
  methods: {}
};
</script>

<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .text {
    display: block;
    font-size: 1em;
    line-height: 150%;
    @include font-site(300, normal);

    &.module {
      max-width: 30em;
    }

    p {
      font-size: 1em;
      line-height: 150%;
      padding: 0px;
      margin: 0.5em 0px 0px 0px;
    }

    b,
    strong {
      //
      @include font-site(600, normal);
      i,
      em {
        @include font-site(600, italic);
      }
    }

    i,
    em {
      //
      @include font-site(300, italic);
      b,
      strong {
        @include font-site(600, italic);
      }
    }

    a {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;

      &:link,
      &:active {
        color: inherit;
      }
      &:hover {
        text-decoration: underline dotted;
      }
      &:visited {
        color: $colorBrown;
      }
    }

    ul {
      margin: 0px;
      padding: 0 0 0 2em;
      //list-style-type: disc;
      list-style-position: outside;

      li {
        display: list-item;
        margin: 0px;
        padding: 0px;
      }
    }

    ol {
      margin: 0px;
      padding: 0 0 0 2em;
      //list-style-type: decimal;
      list-style-position: outside;

      li {
        display: list-item;
        margin: 0px;
        padding: 0px;
      }
    }

    blockquote {
      position: relative;
      display: block;
      box-sizing: border-box;
      font-size: 1em;
      line-height: 110%;
      margin: 0.5em 0px 0.5em 0px;
      padding: 0.5em 0.5em 0.5em 0.5em;
      background-color: $colorGrayLight;

      p {
        padding: 0px;
        margin: 0px;
      }
    }
  }

  @media only screen and (min-width: $max-content-width) {
    .text {
      font-size: 1.5em;
    }
  }
}
</style>
