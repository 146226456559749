<template>
  <div class="masonry " :class="classForBackground">
    <div class="module">
      <ContentMasonryCard v-for="(page, index) in pagesFilteredByTags" :key="'card' + index" :page="page">
      </ContentMasonryCard>
      <!--div v-for="i in columnsCount" class="masonry-column" :style="columnStyle" :key="i + 'column'">
        <template v-for="(page, index) in pagesWithTags">
          <ContentMasonryCard
            v-if="(index - i + 1) % columnsCount === 0 && index < cardLimit"
            :key="'card' + index"
            :page="page"
          >
          </ContentMasonryCard>
        </template>
      </div-->
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import { mapGetters, mapActions } from "vuex";
import handler from "@/plugins/ObjectHandler";
import { detectWidth } from "@/plugins/DomUtil";
import ContentMasonryCard from "@/components/ContentMasonryCard";

export default {
  name: "ContentMasonry",
  components: {
    ContentMasonryCard: ContentMasonryCard
  },
  created: function() {
    //this.loadApiPages();
  },
  mounted: function() {
    const self = this;
    self.setWidth();

    EventBus.$on("resize", function() {
      //self.$log.debug(detectWidth(self.$el));
      self.setWidth();
    });
  },
  watch: {},
  props: {
    content: {
      // type: Object,
      default: function() {
        return {};
      }
    }
  },
  // component internal data
  data: function() {
    return {
      width: 0,
      columnWidth: 300
    };
  },
  computed: {
    ...mapGetters("api", {
      getApiPages: "getApiPages"
    }),

    classForBackground: function() {
      return handler.getProperty("", ["background"], this.content);
    },

    tags: function() {
      return handler.getProperty([], ["tags"], this.content);
    },

    tagsForbidden: function() {
      return handler.getProperty([], ["forbiddentags"], this.content);
    },

    // returns all pages with tags
    /*pagesWithAlowedTags: function() {
      return this.getApiPages.filter(function(page) {
        const pageTags = handler.getProperty([], "tags", page);
        const checkedTags = pageTags.filter(function(tag) {
          return this.tags.indexOf(tag) >= 0;
        }, this);
        return checkedTags.length > 0;
      }, this);
    },*/

    // returns all pages with tags
    pagesWithTags: function() {
      return this.getApiPages.filter(this.pageHasTag);
    },

    // returns all pages with tags and without forbiddentags
    pagesFilteredByTags: function() {
      const allWithTags = this.pagesWithTags;
      return this.tagsForbidden.length > 0 ? allWithTags.filter(this.pageHasForbiddenTag) : allWithTags;
    },

    /*
    // returns the number columns displayable on page - at least 1
    columnsCount: function() {
      return this.width > 0 ? Math.max(Math.floor(this.width / this.columnWidth), 1) : 1;
    },

    // returns the number spacers displayable on page - at least 0
    spacersCount: function() {
      return this.columnsCount - 1;
    },

    // returns the number spacers displayable on page - at least 0
    spacersTotalWidth: function() {
      return this.spacersCount * 7;
    },

    // returns the number spacers displayable on page - at least 0
    columnsTotalWidth: function() {
      return 100 - this.spacersTotalWidth;
    },

    // returns the column width (6% page width per spacer)
    columnsWidth: function() {
      return (Math.floor((this.columnsTotalWidth * 100) / this.columnsCount) / 100).toFixed(2);
    },

    // returns the column style with in percentage
    // first and last column should be narrower by 4%
    columnStyle: function() {
      return {
        width: this.columnsWidth + "%"
      };
    },
*/
    cardLimit: function() {
      const limit = parseInt(handler.getProperty(0, ["limit"], this.content), 10);
      const count = limit + 0 === limit && limit > 0 ? limit : this.getApiPages.length;
      const correction = count % this.columnsCount === 0 ? 0 : this.columnsCount - (count % this.columnsCount);
      return count > 0 ? count + correction : count;
    }
  },

  methods: {
    ...mapActions("api", {
      loadApiPages: "loadApiPages"
    }),

    // detects the width of the masonry element
    setWidth: function() {
      this.width = detectWidth(this.$el);
    },

    // checks if the tags array of a given page contains all the tags returned from property content
    pageHasTag: function(page) {
      const pageTags = handler.getProperty([], "tags", page);
      const filteredTags = pageTags.filter(this.hasTag);
      // return filteredTags.length === this.tags.length; //has all tags
      return filteredTags.length > 0; //has one tag at least
    },

    // checks if tags array returned from property content contains the item provided
    hasTag: function(item) {
      return this.tags.indexOf(item) >= 0;
    },

    // checks if the tags array of a given page contains any of the forbiddentags returned from property content
    pageHasForbiddenTag: function(page) {
      const pageTags = handler.getProperty([], "tags", page);
      const filteredTags = pageTags.filter(this.hasForbiddenTag);
      return filteredTags.length == 0;
    },

    // checks if forbiddentags array returned from property content contains the item provided
    hasForbiddenTag: function(item) {
      return this.tagsForbidden.indexOf(item) >= 0;
    },

    getPageTitle: function(page) {
      handler.getProperty("", ["title"], page);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .masonry {
    padding: 0; //3em 2em 3em 2em;

    .module {
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: strech;
      gap: 0; //3em;
      padding: 0;
    }

    /*
    .masonry-spacer {
      float: left;
      width: 7%;
      height: 2em;
    }
    */

    /*
    .masonry-column {
      float: left;
      margin-left: 7%;
      padding: 0px 3% 0px 3%;

        &:first-child {
          //padding-right: 4%;
          padding-left: 0px;
        }

        &:last-child {
          //padding-left: 4%;
          padding-right: 0px;
        }

      &:first-child {
        margin-left: 0;
      }
    }
    */

    /*&:after {
      position: relative;
      display: block;
      box-sizing: border-box;
      content: "";
      height: 0px;
      width: 100%;
      float: none;
      clear: both;
    }
    */
  }
}
</style>
