<template>
  <div class="footer">
    <ul class="footer-links module">
      <li class="footer-link" v-for="(footer, index) in getMainFooter" :key="index">
        <a class="link" @click="handleFooterLinkClick($event, footer)" :href="getLinkUrl(footer)">{{ footer.menu }}</a>
      </li>
    </ul>
    <!--FooterSub :parentUrl="clickedMenuUrl" :clickedMenuUrl="clickedMenuUrl"></FooterSub-->
    <div class="copy">Copyright © Bright BI Informatika Kft. <br />{{ textRightsReserved }}</div>
  </div>
</template>

<script>
// import { mapState, mapGetters, mapActions } from "vuex";
import { mapGetters } from "vuex";
//import { EventBus } from "@/main.js";
import handler from "@/plugins/ObjectHandler";

// import heplers
import MenuHelper from "@/helpers/MenuHelper";

//import components
//import FooterSub from "@/components/FooterSub";

export default {
  name: "Footer",

  components: {
    //FooterSub
  },

  props: {},

  created: function() {
    //const self = this;
  },

  mounted: function() {},

  // component internal reactive data
  data: function() {
    return {
      // subMenus are visible based on this parameter
      clickedMenuUrl: "",
      textRights: {
        hu: "Minden jog fenntartva.",
        en: "All rights reserved."
      }
    };
  },

  // component computed properties
  computed: {
    // importing getters from vuex with mapGetters (made possible by importing mapGetters from vuex)
    // using JS ES6 syntax for merging properties returned by mapGetters into computed properties
    ...mapGetters("language", {
      getLanguage: "getLanguage"
    }),

    ...mapGetters("api", {
      getApiFooter: "getApiFooter",
      getMainFooter: "getMainFooter"
    }),

    language: function() {
      // in case of default hungarian language the stored string is empty
      return this.getLanguage === "" ? "hu" : this.getLanguage;
    },

    textRightsReserved: function() {
      const exists = Object.prototype.hasOwnProperty.call(this.textRights, this.language);
      return exists ? this.textRights[this.language] : "";
    }
    /*
      getMenuForUrl: function() {
        return handler.getFirstWithPropVal(["page", "url"], this.parentUrl, this.getApiMenu) || {};
      },

      getSubMenuForUrl: function() {
        return handler.getProperty([], ["submenu"], this.getMenuForUrl);
      },

      getSubMenuIdsForUrl: function() {
        return handler.getEachPropVal("_id", this.getSubMenuForUrl);
      },

      getMenuItemsForParentUrl: function() {
        const subMenuIds = this.getSubMenuIdsForUrl;
        var result = [];
        for (var i = 0; i < subMenuIds.length; i++) {
          const menu = this.getMenuWithId(subMenuIds[i]);
          if (menu !== {}) {
            result.push(menu);
          }
        }
        return result;
      }
      */
  },

  // component methods
  methods: {
    requestClickMenu: MenuHelper.requestClickMenu,
    requestCloseMenu: MenuHelper.requestCloseMenu,
    requestPageLoad: MenuHelper.requestPageLoad,

    getLinkUrl: function(item) {
      return handler.getProperty(null, ["page", "url"], item);
    },

    footerHasSubLink: function(item) {
      return Array.isArray(item.submenu) && item.submenu.length > 0;
    },

    handleFooterLinkClick: function(event, item) {
      event.preventDefault();
      var myUrl = this.getLinkUrl(item);
      this.clickedMenuUrl = this.clickedMenuUrl === myUrl ? "" : myUrl;
      if (!this.footerHasSubLink(item)) {
        this.requestPageLoad(myUrl);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
#brightbi {
  .footer {
    //font-size: 0.75em;
    width: 100%;
    margin-top: 0;
    padding: 4em 0 5em 0;
    color: $colorWhite;
    //background-color: $colorTheme;
    /*background-image: linear-gradient(
    to bottom,
    transparent 10%,
    $colorThemeDark 70% $colorThemeDarkest 70%,
    $colorBlack 100%
  );
  */
    //background-image: radial-gradient(130% 90% at center bottom, $colorBlack 0%, $colorThemeDarkest 35%, transparent 95%);
    background-image: radial-gradient(
      130% 90% at center bottom,
      $colorThemeDarkest 0%,
      $colorBlack 35%,
      transparent 95%
    );

    .footer-links {
      display: block;
      list-style: none;
      text-align: left;
      max-width: $max-content-width;
      margin-left: auto;
      margin-right: auto;
      padding: 0 0 1em 0;
      text-align: center;
      border-bottom: 1px solid $colorWhite;

      .footer-link {
        display: inline-block;
        list-style: none;
        font-size: 1em;
        line-height: 100%;
        @include font-site(normal, normal);
        margin: 0px 0.25em 0px 0.25em;
        padding: 0px;

        border: 1px solid $colorTransparent;
        border-radius: 0.25em;
        cursor: pointer;

        &:hover {
          border: 1px solid $colorWhite;
        }

        &.active {
          color: $colorGrayMid;
          background-color: $colorWhite;
        }

        .link {
          position: relative;
          display: block;
          box-sizing: border-box;
          margin: 0px;
          padding: 0.25em 0.25em 0.25em 0.25em;
          color: $colorWhite;
          cursor: pointer;

          &:hover {
            //background-color: $colorGray;
            color: $colorWhite;
          }
        }
      }
    }

    .copy {
      font-size: 0.75em;
      max-width: $max-content-width;
      margin: 0 auto 0 auto;
      padding: 2em 0 2em 0;
      @include font-site(300, normal);
      text-align: center;
    }

    @media only screen and (min-width: $menuBreakPoint) {
    }
  }
}
</style>
