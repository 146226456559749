<template>
  <header class="header">
    <div class="container">
      <div class="menu-button" :class="classMenuButtonActive" v-on:click="toggleMenuButton"></div>
      <MenuLanguage></MenuLanguage>
      <MainMenu :visible="isMenuVisible"></MainMenu>
      <SubMenu v-if="isSubMenuVisible" :parentUrl="clickedMenuUrl" :clickedMenuUrl="clickedMenuUrl"></SubMenu>

      <template v-if="checkMenu(getHomeMenu)">
        <router-link class="site-name" :to="getHomeUrl" @click.native="closeMenuButton">
          <img
            class="logo"
            src="@/assets/img/bbi-logo-inverse.png"
            alt="Bright BI"
            :class="{ smalllogo: isRouteSubPage }"
          />
          <span class="label big">{{ getHomeMenu.menu }}</span>
          <span class="label">{{ getHomeMenu.description }}</span>
        </router-link>
      </template>
    </div>
  </header>
</template>

<script>
//import { mapState, mapGetters, mapActions } from "vuex";
import { mapGetters } from "vuex";
import { EventBus } from "@/main.js";
import handler from "@/plugins/ObjectHandler";

//import components
import MenuLanguage from "@/components/MenuLanguage";
import MainMenu from "@/components/MainMenu";
import SubMenu from "@/components/SubMenu";

export default {
  name: "Header",
  components: {
    MenuLanguage: MenuLanguage,
    MainMenu: MainMenu,
    SubMenu: SubMenu
  },
  props: {},

  created: function() {
    const self = this;
    //handling event notification about updated value
    EventBus.$on("resize", function() {
      self.resetMenuButtonClicked();
    });

    EventBus.$on("closeMenu", function() {
      self.toggleClickedMenuUrl("");
      self.closeMenuButton();
    });

    EventBus.$on("clickMenu", function(value) {
      //self.$log.debug("clickMenu => ", value);
      self.toggleClickedMenuUrl(value);
    });
  },

  mounted: function() {
    this.isMenuButtonVisible();
  },

  // component internal data
  data: function() {
    return {
      menuButtonClicked: false,
      menuButtonVisible: false,
      clickedMenuUrl: ""
    };
  },

  // component computed properties
  computed: {
    // importing state from vuex with mapState (made possible by import statement with mapState from vuex)
    // using JS ES6 syntax for merging properties returned by mapState into computed properties
    /*
        ...mapState("api", {
          apiMenu: "apiMenu"
        }),
        */
    // importing getters from vuex with mapGetters (made possible by importing mapGetters from vuex)
    // using JS ES6 syntax for merging properties returned by mapGetters into computed properties
    ...mapGetters("path", {
      getHomePath: "getHomePath"
    }),

    ...mapGetters("api", {
      getHomeMenu: "getHomeMenu",
      getMainMenu: "getMainMenu"
    }),

    getHomeUrl: function() {
      const page = this.getHomeMenu.page || {};
      return page.url || this.getHomePath;
    },

    classMenuButtonActive: function() {
      return this.menuButtonClicked ? "active" : "";
    },

    isMenuVisible: function() {
      return (this.menuButtonClicked && this.menuButtonVisible) || !this.menuButtonVisible;
    },

    isSubMenuVisible: function() {
      return !this.menuButtonVisible;
    },

    isRouteHomePage: function() {
      return this.$route.path === this.getHomeUrl;
    },

    isRouteSubPage: function() {
      return !this.isRouteHomePage;
    }
  },

  // component methods
  methods: {
    // importing actions from vuex with mapActions (made possible by importing mapActons from vuex)
    // using JS ES6 syntax for merging properties returned by mapActons into methods
    // the first parameter is the namespace
    /*
        ...mapActions("api", {
          loadApiMenu: "loadApiMenu"
        })
        */
    //this is not needed
    /*async getMenu() {
          const response = await MenuService.getMenu("");
          this.$log.info(response.data);
        }*/

    //checks if a given menu object has valid required properties
    checkMenu: function(menu) {
      return (
        handler.getProperty("null", ["page", "url"], menu) !== null &&
        handler.getProperty("null", "menu", menu) !== null &&
        handler.getProperty("null", "description", menu) !== null
      );
    },

    //
    closeMenuButton: function() {
      this.menuButtonClicked = false;
    },

    toggleMenuButton: function() {
      this.menuButtonClicked = !this.menuButtonClicked;
    },

    isMenuButtonVisible: function() {
      //get a collection of child elements with specified class
      const elem = this.$el ? this.$el.getElementsByClassName("menu-button") : [];
      const button = elem.length > 0 ? elem[0] : null;
      this.menuButtonVisible = button !== null ? this.isElemVisible(button) : false;
    },

    isElemVisible: function(elem) {
      return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
    },

    resetMenuButtonClicked: function() {
      if (this.isMenuButtonVisible() === false) {
        this.menuButtonClicked = false;
      }
    },

    requestCloseMenu: function() {
      EventBus.$emit("closeMenu");
    },

    toggleClickedMenuUrl: function(theUrl) {
      this.clickedMenuUrl = theUrl === this.clickedMenuUrl ? "" : theUrl;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi {
  .header {
    font-size: 1em;
    width: 100%;

    height: auto;
    max-width: 80em;
    margin: 0 auto 0 auto;
    padding: 0.5em 0 0 0;
    background-image: radial-gradient(60% 90% at top center, $colorTheme 0%, $colorThemeDark 25%, transparent 100%);
    background-position: top center;

    .container {
      max-width: $max-content-width;
      margin: 0 auto 0 auto;
      padding: 0;
    }

    .menu-button {
      position: relative;
      display: block;
      box-sizing: border-box;
      width: 1.5em;
      height: 1.4375em;
      margin: 0 auto 0 auto;
      padding: 0;
      border: 1px solid $colorWhite;
      border-radius: 0.25em;
      overflow: hidden;
      background: none;
      cursor: pointer;

      &:hover {
        //box-shadow: 0 0.125em 0.25em $colorBlackTranslucent;
        filter: drop-shadow(0 0.125em 0.125em $colorBlackTranslucent);
        cursor: pointer;
      }

      &:before {
        position: absolute;
        display: block;
        box-sizing: border-box;
        content: "";
        top: 0.3125em;
        left: 0.1875em;
        width: 1em;
        height: 0.6875em;
        margin: 0;
        padding: 0;
        border-left: none;
        border-right: none;
        border-top: 1px solid $colorWhite;
        border-bottom: 1px solid $colorWhite;
        transition-property: top height transform;
        transition-duration: 0.5s;
        //box-shadow: inherit;
      }

      &:after {
        position: absolute;
        display: block;
        box-sizing: border-box;
        content: "";
        top: 0.625em;
        left: 0.1875em;
        width: 1em;
        height: 1px;
        margin: 0;
        padding: 0;
        border: none;
        background-color: $colorWhite;
        transition-property: transform;
        transition-duration: 0.5s;
      }

      &.active {
        &:before {
          top: 0.625em;
          height: 1px;
          border-bottom: none;
          transform: rotate(45deg);
          transition-property: transform;
          transition-duration: 0.5s;
        }

        &:after {
          transform: rotate(-45deg);
          transition-property: transform;
          transition-duration: 0.5s;
        }
      }
    }

    .site-name {
      display: block;
      box-sizing: border-box;
      font-size: 1.5em;
      line-height: 100%;
      width: 25%;
      height: auto;
      margin: 0px auto 0px auto;
      padding: 0px;
      @include font-site(300, normal);

      .logo {
        position: relative;
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        padding: 0px;
        filter: drop-shadow(0 0.125em 0.25em $colorBlackTranslucent);
      }

      .label {
        display: none;
        box-sizing: border-box;
        font-size: 1em;
        line-height: 100%;
        letter-spacing: 0.5px;

        &.big {
          font-size: 1.333em;
        }
      }
    }
  }

  @media only screen and (min-width: 640px) {
    .header {
      background-image: radial-gradient(50% 90% at top center, $colorTheme 0%, $colorThemeDark 25%, transparent 100%);
      background-position: top center;

      /*

  .container {
        max-width: 78em;
      }
*/
      .menu-button {
        display: none;
      }

      .site-name {
        display: block;
        position: relative;
        box-sizing: border-box;

        .logo {
          display: block;
          position: relative;
          box-sizing: border-box;
          width: 9em;
          transition: width 0.25s;

          &.smalllogo {
            width: 4.5em;
          }
        }
      }
    }
  }
}
</style>
