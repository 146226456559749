<template>
  <div class="video module">
    <div class="embed" v-html="embed"></div>
    <span class="detail" v-if="detail !== false">{{ detail }}</span>
  </div>
</template>

<script>
import handler from "@/plugins/ObjectHandler";

export default {
  name: "ContentVideo",
  created: function() {},
  watch: {},
  props: {
    content: {
      //type: Object,
      default: function() {
        return {};
      }
    }
  },
  // component internal data
  data: function() {
    return {};
  },
  computed: {
    embed: function() {
      return handler.getProperty(false, "embed", this.content);
    },
    detail: function() {
      return handler.getProperty(false, "detail", this.content);
    }
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .video {
    font-size: 1em;
    @include font-site(300, normal);
    padding-top: 2em;
    padding-bottom: 2em;
    color: $colorGray;

    .embed {
      width: 100%;
      padding-top: 60%;

      iframe {
        display: block;
        position: absolute;
        box-sizing: border-box;
        border: none;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
      }
    }

    .detail {
      display: block;
      font-size: 1.125em;
      line-height: 120%;
      @include font-site(300, normal);
      color: inherit;
    }
  }
}
</style>
