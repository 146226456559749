<template>
  <slide-up-down :active="isVisible" :duration="500" class="navigation" tag="nav">
    <ul class="menu">
      <li class="menu-item" v-for="(menu, index) in getMainMenu" :key="index">
        <a class="link" :href="menu.page.url" :title="menu.description" @click="handleMenuClick($event, menu)">{{
          menu.menu
        }}</a>
        <!--SubMenu :parentUrl="menu.page.url" :clickedMenuUrl="clickedMenuUrl"></!--SubMenu-->
      </li>
    </ul>
  </slide-up-down>
</template>

<script>
// import { mapState, mapGetters, mapActions } from "vuex";
import { mapGetters } from "vuex";
import { EventBus } from "@/main.js";
// import handler from "@/plugins/ObjectHandler";

// import heplers
import MenuHelper from "@/helpers/MenuHelper";

//import components
//import SubMenu from "@/components/SubMenu";

export default {
  name: "MainMenu",

  components: {
    //SubMenu: SubMenu
  },

  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },

  created: function() {
    const self = this;

    // clearing the clickedMenuUrl, if empty the sub menus will be hidden
    EventBus.$on("closeMenu", function() {
      self.toggleClickedMenuUrl("");
    });
  },

  mounted: function() {},

  // component internal reactive data
  data: function() {
    return {
      // subMenus are visible based on this parameter
      clickedMenuUrl: ""
    };
  },

  // component computed properties
  computed: {
    // importing getters from vuex with mapGetters (made possible by importing mapGetters from vuex)
    // using JS ES6 syntax for merging properties returned by mapGetters into computed properties
    ...mapGetters("language", {
      getLanguage: "getLanguage"
    }),

    ...mapGetters("api", {
      getApiMenu: "getApiMenu",
      getMainMenu: "getMainMenu"
    }),

    isVisible: function() {
      return this.visible;
    },

    //this.$log.debug(this.$route.path);
    language: function() {
      //return MenuHelper.currentLanguage(this.$route.path);
      return this.getLanguage;
    }
  },

  // component methods
  methods: {
    requestClickMenu: MenuHelper.requestClickMenu,
    requestCloseMenu: MenuHelper.requestCloseMenu,
    requestPageLoad: MenuHelper.requestPageLoad,

    menuHasSubMenu: function(menu) {
      return Array.isArray(menu.submenu) && menu.submenu.length > 0;
    },

    toggleClickedMenuUrl: function(url) {
      //this.$log.debug(url);
      this.clickedMenuUrl = this.clickedMenuUrl === url ? "" : url;
    },

    // handles the click event on menuItems
    // prvents default event propagation
    // checks if menuItem has subMenus
    // toggles the clickedMenuUrl, based on this parameter apropriate subMenu is toggled
    // requests closing of menu if there are no submenus
    // requests page load if there are no subMenus
    handleMenuClick: function(event, menu) {
      event.preventDefault();
      //const hasSubMenu = this.menuHasSubMenu(menu);
      this.toggleClickedMenuUrl(menu.page.url);
      this.requestClickMenu(menu.page.url);
      //if (!hasSubMenu) {
      this.requestCloseMenu();
      this.requestPageLoad(menu.page.url);
      //}
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
#brightbi .header {
  .navigation {
    display: block;
    list-style: none;
    background: none;
    max-width: 30em;
    margin: 0.5em auto 0px auto;
    padding: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.25);

    .menu {
      position: relative;
      display: block;
      box-sizing: border-box;
      list-style: none;
      background: none;
      margin: 0;
      padding: 0;
    }

    .menu-item {
      list-style: none;
      font-size: 1em;
      line-height: 100%;
      @include font-site(normal, normal);
      margin: 0;
      padding: 0.5rem 0 0.5em 0;
      border-top: 1px solid rgba(255, 255, 255, 0.125);
      cursor: pointer;

      &:first-child {
        border-top: none;
      }

      .link {
        position: relative;
        display: block;
        box-sizing: border-box;
        font-size: 1em;
        margin: 0;
        padding: 0;
        border: none;
        color: $colorWhite;
        @include font-site(300, normal);

        line-height: 110%;
        border: 1px solid $colorTransparent;

        text-shadow: none;
        letter-spacing: 0;
        transition-property: text-shadow, letter-spacing;
        transition-duration: 0.5s;
        cursor: pointer;

        &:hover {
          // background-color: $colorBrown;
          // color: $colorWhite;
          //border: 1px solid $colorWhite;

          text-shadow: 0 0.125em 0.125em $colorBlack;
          letter-spacing: 1px;
          transition-property: text-shadow, letter-spacing;
          transition-duration: 0.5s;
          cursor: pointer;
        }
      }
    }
  }

  @media only screen and (min-width: 640px) {
    //adjusted in acording to header
    .navigation {
      display: block;
      max-width: none;
      text-align: center;
      margin: 0;
      padding: 1.5em 0 0 0;
      background: none;

      .menu-item {
        display: inline-block;
        margin: 0;
        padding: 0 1em 0 1em;
        border-top: none;
        border-left: 1px solid $colorWhite;

        &:first-child {
          border-left: none;
        }
        //border: 1px solid red;

        .link {
          font-size: 1.25em;
          font-size: 2.5vw;

          //text-shadow: 0px 0px 0.25em $colorTheme, 0.25em 0px 0.25em $colorTheme;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    //adjusted in acording to header
    .navigation {
      .menu-item {
        .link {
          font-size: 1.75em;
          //font-size: 2vw;

          //text-shadow: 0px 0px 0.25em $colorTheme, 0.25em 0px 0.25em $colorTheme;
        }
      }
    }
  }
}
</style>
