// creating plugin
// returns util functions for dom element handling
// returns absolute offset object {x, y} relative to the document root element
export const getAbsoluteOffsetFromDocument = function(elem) {
  const pos = { x: 0, y: 0 };
  // checking if elem is a valid Element
  if (typeof elem === "object" && typeof elem.getBoundingClientRect === "function") {
    // getBoundingClientRect is compatible wayback with oldest web browsers, thus is a very secure way to calculate offset
    const doc = document.documentElement;
    const rectDoc = doc.getBoundingClientRect();
    const rectElem = elem.getBoundingClientRect();
    // allways make sure not to return smaller nuber than 0
    pos.x = Math.max(0, Math.round(rectElem.left - rectDoc.left));
    pos.y = Math.max(0, Math.round(rectElem.top - rectDoc.top));
  }
  return pos;
};

// detects the computed content width without margins, border or padding
export const detectWidth = function(elem) {
  var width = 0;
  if (isHTML(elem)) {
    width = detectComputedStyleWidth(elem);
    width = isValidWidth(width) ? width : detectCurrentStyleWidth(elem);
    width = isValidWidth(width) ? width : detectClientWidth(elem);
  }
  return width;
};

const isValidWidth = function(width) {
  return width + 0 === width && width > 0;
};

// detecting with based on computed style
const detectComputedStyleWidth = function(elem) {
  return window.getComputedStyle ? parseFloat(window.getComputedStyle(elem).width) : 0;
};

// detecting width based on current style
const detectCurrentStyleWidth = function(elem) {
  return elem.currentStyle ? parseFloat(elem.currentStyle.width) : 0;
};

// detecting width based on clientWidth
const detectClientWidth = function(elem) {
  return elem.clientWidth;
};

// detecting if element is HTML DOM elem
const isHTML = function(elem) {
  return elem !== undefined && elem instanceof Node;
};
