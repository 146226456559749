<template>
  <router-link class="masonry-card" :to="url">
    <span class="card-body" :style="bodyStyle">
      <span v-if="!hasImage" class="background"></span>
      <ContentPicture v-if="hasImage" :content="pictureContent" class="background"></ContentPicture>
      <!--span class="card-title" :class="textColorClass">{{ title }}</!--span-->
      <span class="card-info" :class="textColorClass">{{ info }}</span>
      <ContentLinkArrow :class="textColorClass"></ContentLinkArrow>
    </span>
  </router-link>
</template>

<script>
import { RGBfromHex, HSLfromRGB } from "@/plugins/ColorUtil";
import { padStringLeft } from "@/plugins/StringUtil";
import handler from "@/plugins/ObjectHandler";
import ContentPicture from "@/components/ContentPicture";
import ContentLinkArrow from "@/components/ContentLinkArrow";

export default {
  name: "ContentMasonryCard",
  components: {
    ContentPicture: ContentPicture,
    ContentLinkArrow: ContentLinkArrow
  },
  created: function() {},
  watch: {},
  props: {
    page: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  // component internal data
  data: function() {
    return {};
  },
  computed: {
    url: function() {
      return handler.getProperty("", ["url"], this.page);
    },

    title: function() {
      return handler.getProperty("", ["title"], this.page);
    },

    info: function() {
      return handler.getProperty("", ["info"], this.page);
    },

    image: function() {
      return handler.getProperty({}, ["image"], this.page);
    },

    imageId: function() {
      return handler.getProperty("", ["_id"], this.image);
    },

    imageColors: function() {
      return handler.getProperty([], ["colors"], this.image);
    },

    backgroundColor: function() {
      const len = this.imageColors.length - 1;
      return len >= 0 ? this.imageColors[0] : this.randomBackground;
    },

    hasImage: function() {
      return this.imageId !== "";
    },

    pictureContent: function() {
      return {
        _id: this.imageId
      };
    },

    bodyStyle: function() {
      return {
        backgroundColor: this.backgroundColor
      };
    },

    randomBackground: function() {
      const max = 16777215; //256 * 256 * 256-1;
      const min = 0;
      const rand = Math.floor(Math.random() * (max - min + 1)) + min;
      const hex = padStringLeft(rand.toString(16), 6, 0);
      return "#" + hex;
    },

    backgroundColorLightness: function() {
      const rgb = RGBfromHex(this.backgroundColor);
      const hsl = HSLfromRGB(rgb.r, rgb.g, rgb.b);
      return hsl.l;
    },

    textColorClass: function() {
      return this.backgroundColorLightness < 45 ? "white" : null;
    }
  },
  methods: {
    cardClick: function() {}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi .content .masonry {
  .masonry-card {
    position: relative;
    display: block;
    box-sizing: border-box;
    //width: auto; //80%;
    margin: 0 auto 0 auto;
    padding: 0px;
    cursor: pointer;

    //flex-grow: 1;
    //flex-shrink: 0;
    //flex-basis: 0;
    flex: 0 1 auto;
    width: 100%;
    min-width: 100%;

    //perspective-origin: 0% 50%;
    perspective: 1000px;

    &:hover .card-body {
      //box-shadow: 0px 0.5em 1em $colorBlackTranslucent;
      //transform: rotateX(10deg);
      .background {
        margin: 0 -0.5em 0 -0.5em;
      }

      .icon {
        .shape {
          fill-opacity: 1;
        }
      }
    }

    .card-body {
      position: relative;
      display: block;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      margin: 0px;
      padding: 0em 0 6em 0;
      overflow: hidden;
      //border-radius: 7px;
      transform-style: preserve-3d;
      //transition: transform 0.25s, box-shadow 0.25s;

      .icon {
        left: 50%;
        bottom: 2em;
        width: 3.5em;
        height: 3.5em;
        margin-left: -1.75em;

        .shape {
          fill: $colorBlack;
          fill-opacity: 0.5;
          //stroke: $colorBlack;
          //stroke-width: 1px;

          transition-property: fill-opacity;
          transition-timing-function: ease;
          transition-duration: 0.5s;
        }

        &.white .shape {
          fill: $colorWhite;
        }
      }

      .background {
        position: relative;
        display: block;
        box-sizing: border-box;
        min-height: 0px;
        width: auto;
        margin: 0 0 0 0;
        padding: 50% 0px 0px 0px;
        text-align: center;
        background-color: inherit;
        text-align: center;

        transition-property: margin;
        transition-timing-function: ease;
        transition-duration: 0.5s;

        .image {
          position: absolute;
          //min-height: 100%;
          //height: 100%;
          height: auto;
          width: 100%;
          top: 0;
          margin: 0 auto 0 auto;
          padding: 0px 0px 0px 0px;
        }

        &.red {
          background-color: #ff0000;
        }

        /*
        &:after{
          position: absolute;
          display: block;
          box-sizing: border-box;
          background-color: ;
        }
        */
      }
      /*
      .card-title {
        position: relative;
        display: block;
        box-sizing: border-box;
        font-size: 1.25em;
        margin: 0px;
        padding: 0.5em 1em 0.5em 1em;
        @include font-site(300, normal);
        line-height: 110%;
        color: $colorGrayDarker;

        &.white {
          color: $colorWhite;
        }
      }
      */

      .card-info {
        position: relative;
        display: block;
        box-sizing: border-box;
        font-size: 1em;
        max-width: 25em;
        margin: 0 auto 0 auto;
        padding: 2em 2em 1em 2em;
        @include font-site(300, normal);
        line-height: 150%;
        color: $colorBlack;

        &.white {
          color: $colorWhite;
        }
      }
    }
  }

  @media only screen and (min-width: 640px) {
    .masonry-card {
      flex-grow: 1;
      //flex-shrink: 1;
      //flex-basis: 50%;
      width: 50%;
      min-width: 50%;
    }
  }

  @media only screen and (min-width: 780px) {
    .masonry-card {
      flex-grow: 1;
      //flex-shrink: 1;
      //flex-basis: 50%;
      width: 50%;
      min-width: 50%;
    }
  }

  @media only screen and (min-width: $max-content-width) {
    .masonry-card {
      flex-grow: 1;
      //flex-shrink: 1;
      //flex-basis: 25%;
      width: 50%;
      min-width: 50%;

      .card-body {
        .card-info {
          font-size: 1.5em;
        }
      }
    }
  }
}
</style>
