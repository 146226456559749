<template>
  <div class="content">
    <component
      v-for="(child, index) in childrenComponents"
      :key="index"
      :is="child.comp"
      :content="child.content"
    ></component>
    <!--pre>{{ contentObjects }}</!--pre-->
  </div>
</template>

<script>
//import { mapState, mapGetters, mapActions } from "vuex";
import { mapGetters, mapActions } from "vuex";
import handler from "@/plugins/ObjectHandler";
//import { EventBus } from "@/main.js";

// import heplers
import MenuHelper from "@/helpers/MenuHelper";

//import content components
import title from "@/components/ContentTitle";
//import subtitle from "@/components/ContentSubtitle";
import text from "@/components/ContentText";
import picture from "@/components/ContentPicture";
//import gallery from "@/components/ContentGallery";
import hero from "@/components/ContentHero";
import video from "@/components/ContentVideo";
import iframe from "@/components/ContentIframe";
import masonry from "@/components/ContentMasonry";
import links from "@/components/ContentLinks";

export default {
  name: "Content",
  created: function() {},
  watch: {
    $route: function() {
      //this.$log.debug("route changed");
      //store.commit("language/setLanguage", "en");
      this.loadApiPage();
    }

    /*getApiPage: function() {
      this.renderContent();
    }*/
  },
  mounted: function() {
    // detecting full render
    this.$nextTick(function() {
      //this.$log.debug("mounted and nextTick happened");
      //this.$log.debug("emmited fullRender");
      ///EventBus.$emit("fullRender");
    });
  },
  // component internal data
  data: function() {
    return {
      // children is an array list of objects containing components, and associated values
      // {comp: VueComponent, content: ContentValue}
      // comp is reference to a vue component
      // content is a value object, containing the data needed by the component
      children: [],
      // object list of components and their coresponding names used for identification
      // the property names are the names used for identifying the components
      // the values stored in the properties are the Vue components themselves
      contentComponents: {
        title: title,
        //subtitle: subtitle,
        text: text,
        picture: picture,
        hero: hero,
        //gallery: gallery,
        //events: events,
        //churches: churches,
        video: video,
        iframe: iframe,
        masonry: masonry,
        links: links
      }
    };
  },
  computed: {
    ...mapGetters("language", {
      getLanguage: "getLanguage"
    }),

    ...mapGetters("api", {
      getApiPage: "getApiPage"
    }),

    // array containing each object from the apiPage response
    contentObjects: function() {
      var content = handler.getProperty([], [0, "content"], this.getApiPage);
      //this.$log.debug("content", content);
      return content;
    },

    // contains only the object having components capable of handling them
    filteredContentObjects: function() {
      return this.contentObjects.filter(function(contentItem) {
        const type = handler.getProperty(null, ["field", "name"], contentItem);
        const contentComponent = this.contentComponents[type];
        return contentComponent !== undefined;
      }, this);
    },

    // returns an array with component - content pairs
    childrenComponents: function() {
      return this.filteredContentObjects.map(function(contentItem) {
        const type = handler.getProperty(null, ["field", "name"], contentItem);
        const value = handler.getProperty(null, ["value"], contentItem);
        const contentComponent = this.contentComponents[type];
        return {
          comp: contentComponent,
          content: value
        };
      }, this);
    },

    contentIsEmpty: function() {
      return this.children.length <= 0;
    },

    currentPath: function() {
      //this.$log.debug("currentPath", this.$route.path, "/");
      //return this.$router.currentRoute.path;
      return this.$route.path;
    },

    currentPathIsHome: function() {
      //this.$log.debug("currentPathIsHome", this.currentPath, "/");
      return this.currentPath === "/" || this.currentPath === "/en/";
    },

    shouldLoadHomePage: function() {
      return this.contentIsEmpty && !this.currentPathIsHome;
    },

    language: function() {
      //return MenuHelper.currentLanguage();
      return this.getLanguage;
    }
  },
  methods: {
    ...mapActions("api", {
      loadApiPage: "loadApiPage"
    }),

    requestPageLoad: MenuHelper.requestPageLoad,

    /*
    renderContent: function() {
      this.children = [];
      this.contentObjects.forEach(function(value) {
        this.renderElem(value);
      }, this);
      //
      // this.createTestElem();
      // checking if there is any rendered content
      // if not redirecting to the main page
      if (this.shouldLoadHomePage) {
        this.requestPageLoad("/");
      }
    },

    // adds a component and content value pair to the data.children array
    // and the content is rendered accordingly based on data.children
    renderElem: function(elemObj) {
      const type = handler.getProperty(null, ["field", "name"], elemObj);
      const value = handler.getProperty(null, ["value"], elemObj);
      const contentComponent = this.contentComponents[type];
      if (contentComponent !== undefined) {
        //this.$log.debug("type", type, "value", value);
        //we need to use $set this way Vue reactivity system will work properly
        this.$set(this.children, this.children.length, {
          comp: contentComponent,
          content: value
        });
      }
    },
    */

    createTestElem: function() {
      this.$set(this.children, this.children.length, {
        comp: this.contentComponents.events,
        content: {}
      });
    }
  }

  //
  /*render: function(createElement) {
      this.renderContent(createElement);
    }*/
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi {
  .content {
    font-size: 1em;
    width: 100%;
    height: auto;
    padding: 0;
    color: $colorWhite;
    //background-color: $colorWhite;

    .module {
      //padding-left: 1.125em;
      //padding-right: 1.125em;
      padding-left: 2em;
      padding-right: 2em;
      max-width: $max-content-width; //80em;
      margin-left: auto;
      margin-right: auto;

      .module {
        max-width: none;
      }
    }

    .light {
      //background-image: linear-gradient(to bottom, transparent 10%, $colorThemeDark 70%, $colorTheme 100%);
      background-color: $colorTheme;
    }

    .bottomLight {
      background-image: linear-gradient(to bottom, transparent 10%, $colorThemeDark 70%, $colorTheme 100%);
    }

    .topLight {
      background-image: linear-gradient(to top, transparent 10%, $colorThemeDark 70%, $colorTheme 100%);
    }

    .rightLight {
      background-image: radial-gradient(60% 90% at 80% center, $colorTheme 10%, $colorThemeDark 35%, transparent 100%);
    }
  }

  /*@media only screen and (min-width: $breakLimit) {
    .module {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
  @media only screen and (min-width: $breakPoint) {
    .module {
      padding-left: 15%;
      padding-right: 15%;
    }
  }*/
}
</style>
