<template>
  <slide-up-down :active="shouldShow" :duration="500">
    <div class="loader"></div>
  </slide-up-down>
</template>

<script>
import { EventBus } from "@/main.js";
export default {
  name: "Loader",
  created: function() {},

  // component data properties
  data: function() {
    return {};
  },

  // component computed values
  computed: {
    // returns true if data loading has at least one property with true value
    shouldShow: function() {
      EventBus.$emit(this.loadStatus);
      return this.loadInProgress;
    },

    //rtreurns true or false dependig if there is currently a load in progress or not
    loadInProgress: function() {
      return this.$store.state.loader.loading > 0;
    },

    // returns the load status event name used for emitting on EventBus
    loadStatus: function() {
      return this.loadInProgress === true ? "loadStarted" : "loadFinished";
    }
  },

  filters: {},

  methods: {},

  watch: {}
};
</script>

<style lang="scss">
// Add "scoped" attribute to limit CSS to this component only
// @import "@/scss/_variables.scss";   //imported globally

/*
#brightbi .loader {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 1em;
  margin: 0px;
  padding: 0.5em 0px 1em 0px;
  width: 100%;

  .image {
    position: relative;
    display: none;
    box-sizing: border-box;
    font-size: 1em;
    width: 10em;
    height: auto;
    margin: 0px auto 0px auto;
    padding: 0px;
  }
}*/

#brightbi .loader {
  position: relative;
  display: block;
  box-sizing: border-box;
  font-size: 4.5em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 0.5em auto;
  padding: 0;
  color: $colorTheme;
  background: none;
  transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
