<template>
  <div class="box module">
    <div class="embed">
      <iframe :src="url"></iframe>
    </div>
  </div>
</template>

<script>
import handler from "@/plugins/ObjectHandler";

export default {
  name: "ContentIframe",
  created: function() {},
  watch: {},
  props: {
    content: {
      //type: Object,
      default: function() {
        return {};
      }
    }
  },
  // component internal data
  data: function() {
    return {};
  },
  computed: {
    url: function() {
      return handler.getProperty(false, "url", this.content);
    },
    detail: function() {
      return handler.getProperty(false, "detail", this.content);
    }
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */
#brightbi .content {
  .box {
    font-size: 1em;
    @include font-site(300, normal);
    padding-top: 2em;
    padding-bottom: 2em;
    color: $colorGray;
    .embed {
      width: 100%;
      padding-top: 100%;

      iframe {
        display: block;
        position: absolute;
        box-sizing: border-box;
        border: none;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (min-width: $breakLimit) {
      .embed {
        padding-top: 60%;
      }
    }
  }
}
</style>
