// creating plugin
// returning an object with properties or methods
export default {
  // ***************************************************************************
  // adds a resize listener to the window and passes a callback function to a throttle function
  addResizeListener: function(callback) {
    var self = this;
    window.addEventListener("resize", function() {
      self.throttle(callback);
    });
  },

  // ***************************************************************************
  // adds a resize listener to the window and passes a callback function to a throttle function
  removeResizeListener: function(callback) {
    var self = this;
    window.removeEventListener("resize", function() {
      self.throttle(callback);
    });
  },

  // limiting the callbacks fired by the window resize event
  // window resize event can trigger hundereds of events per second
  // we only allow one function call per half a second
  // we need to store this in self variable for correct context in timeout callback funtion
  // the timer id is saved and deleted before setting new timer for avoiding multiple timers
  throttle: function(callback) {
    var self = this;
    if (self.isFunction(callback)) {
      window.clearTimeout(self.__timer);
      self.__timer = window.setTimeout(function() {
        callback();
      }, 150);
    }
  },

  // ***************************************************************************
  isFunction: function(object) {
    return !!(object && object.constructor && object.call && object.apply);
  }
};
