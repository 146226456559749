import { EventBus } from "@/main.js";
import { forceToString } from "@/plugins/StringUtil.js";

/*const currentPath = function() {
  return this.$route.path;
};*/

const currentLanguage = function(routePath) {
  //console.log("mypath", this.$route);
  const pathStr = forceToString(routePath, "/");
  console.log("mypath", pathStr);
  const regexpRule = /^\/en(\/.*)?$/;
  return regexpRule.test(pathStr) ? "en" : "hu";
};

const requestPageLoad = function(pageUrl) {
  if (this.$route.path !== pageUrl) {
    this.$router.push({ path: pageUrl }).catch({});
  }
};

const requestCloseMenu = function() {
  EventBus.$emit("closeMenu");
};

const requestClickMenu = function(pageUrl) {
  EventBus.$emit("clickMenu", pageUrl);
};

export default {
  //currentPath,
  currentLanguage,
  requestClickMenu,
  requestCloseMenu,
  requestPageLoad
};
