import { forceToString } from "@/plugins/StringUtil.js";

const validLanguages = ["hu", "en"];

// returns the detected language from a path
const getLanguageFromPath = function(routePath) {
  const pathStr = forceToString(routePath, "/");
  const filtered = validLanguages.filter(function(lang) {
    const regexpRule = new RegExp("^/(" + lang + ")(/.*)?$", "gi");
    const found = regexpRule.test(pathStr);
    return found;
  });
  const result = filtered.length > 0 ? filtered[0] : "";
  return result;
};

export default {
  //currentPath,
  validLanguages,
  getLanguageFromPath
};
