<template>
  <slide-up-down :active="isVisible" :tag="'ul'" class="submenu">
    <!--ul class="submenu"-->
    <li class="menu-item" v-for="(menuItem, index) in getMenuItemsForParentUrl" :key="index">
      <router-link
        class="link"
        :key="menuItem.id"
        :title="menuItem.description"
        :to="menuItem.page.url"
        @click.native="requestCloseMenu"
      >
        <img class="icon" :src="picturePath(menuItem)" />
        {{ menuItem.menu }}
      </router-link>
    </li>
    <!--ul-->
  </slide-up-down>
</template>

<script>
//import { mapState, mapGetters, mapActions } from "vuex";
import { mapGetters } from "vuex";
//import { EventBus } from "@/main.js";
import handler from "@/plugins/ObjectHandler";

// iport heplers
import MenuHelper from "@/helpers/MenuHelper";

export default {
  name: "SubMenu",
  props: {
    parentUrl: {
      type: String,
      default: ""
    },
    clickedMenuUrl: {
      type: String,
      default: ""
    }
  },

  created: function() {},

  mounted: function() {},

  // component internal data
  data: function() {
    return {};
  },

  // component computed properties
  computed: {
    // importing getters from vuex with mapGetters (made possible by importing mapGetters from vuex)
    // using JS ES6 syntax for merging properties returned by mapGetters into computed properties
    ...mapGetters("api", {
      getApiMenu: "getApiMenu"
    }),

    // currentPath: MenuHelper.currentPath,

    isVisible: function() {
      return this.clickedMenuUrl !== "" && this.clickedMenuUrl === this.parentUrl;
    },

    getMenuForUrl: function() {
      return handler.getFirstWithPropVal(["page", "url"], this.parentUrl, this.getApiMenu) || {};
    },

    getSubMenuForUrl: function() {
      return handler.getProperty([], ["submenu"], this.getMenuForUrl);
    },

    getSubMenuIdsForUrl: function() {
      return handler.getEachPropVal("_id", this.getSubMenuForUrl);
    },

    getMenuItemsForParentUrl: function() {
      const subMenuIds = this.getSubMenuIdsForUrl;
      var result = [];
      for (var i = 0; i < subMenuIds.length; i++) {
        const menu = this.getMenuWithId(subMenuIds[i]);
        if (menu !== {}) {
          result.push(menu);
        }
      }
      return result;
    }
  },

  // component methods
  methods: {
    requestCloseMenu: MenuHelper.requestCloseMenu,
    requestPageLoad: MenuHelper.requestPageLoad,

    getMenuWithId: function(id) {
      return this.getApiMenu.find(menu => menu._id === id) || {};
    },

    pictureId: function(menuItem) {
      return handler.getProperty(false, ["icon", "_id"], menuItem);
    },

    picturePath: function(menuItem) {
      const myId = this.pictureId(menuItem);
      return myId === false ? false : "/api/picture/" + myId;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "lang=scss" attribute to allow sass -->
<style lang="scss">
/* base font size is 16px */

// styling for mobile view mode
#brightbi .header .menu {
  .submenu {
    list-style: none;
    background-color: $colorGrayLighter;
    border-bottom: 1px solid $colorWhite;
    //margin: 0px -1.25em 0px -1.25em;

    .menu-item {
      list-style: none;
      font-size: 1em;
      line-height: 100%;
      @include font-site(300, normal);
      margin: 0px;
      padding: 0px;
      border-top: 1px solid $colorWhite;

      &:first-child {
        border-top: 1px solid $colorWhite;
      }

      .link {
        position: relative;
        display: block;
        box-sizing: border-box;
        padding: 0.5em 1.25em 0.5em 2.5em;
      }

      &:hover {
        background-color: $colorBrown;

        .link {
          color: $colorWhite;
        }
      }
    }
  }

  @media only screen and (min-width: $menuBreakPoint) {
    .submenu {
      display: none;
    }
  }
}

// styling for desktop view mode
#brightbi .header {
  .submenu {
    display: block;
    list-style: none;
    clear: both;
    padding: 0px;
    background-color: $colorGrayLighter;
    text-align: left;

    .menu-item {
      position: relative;
      display: block;
      box-sizing: border-box;
      margin: 1em 0.5em 1em 0.5em;

      .link {
        position: relative;
        display: block;
        box-sizing: border-box;
        padding: 0px 0px 0px 0px;
        cursor: pointer;

        .icon {
          display: none;
        }

        &:hover {
          color: $colorBrown;
        }
      }
    }
  }

  @media only screen and (min-width: $menuBreakPoint) {
    .submenu {
      display: block;
      padding: 0px 1.25em 0px 1.25em;
      text-align: center;

      .menu-item {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        min-width: 15%;
        min-height: 5em;
        margin: 1em 0.5em 1em 0.5em;

        .link {
          position: relative;
          display: block;
          box-sizing: border-box;
          padding: 4em 0px 0px 0px;
          cursor: pointer;

          .icon {
            position: absolute;
            display: block;
            box-sizing: border-box;
            left: 50%;
            top: 0px;
            margin: 0px 0px 0px -1.75em;
            width: 3.5em;
            height: 3.5em;
            //background: gray;
          }

          &:hover {
            color: $colorBrown;
          }
        }
      }
    }
  }
}
</style>
